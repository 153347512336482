.Page {
  /* width: 80%; */
  /* margin-left: auto;
  margin-right: auto; */
  padding-top: 5rem;
  min-height: 100dvh;
}

.Page .info-group {
  display: grid;
  grid-template-columns: 1fr 6fr;
  margin-bottom: 2rem;
  margin-top: 2rem;
} 

.Page .info-group div {
  margin: 0;
}

.Page img {
  height: auto;
  width: 7rem;
  border-radius: 20px;
  justify-self: right;
  align-self: start;
  grid-column: 1;
  grid-row: 1;
  margin-right: 1rem;
}

.App[data-theme="dark"] .icon {
  filter: brightness(1);
}

.App[data-theme="light"] .icon {
  filter: brightness(.25);
}

.Page h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.Page h1 span, 
.Page h2 {
  font-size: 1.5rem;
}

.Page h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.ThemeMenu {
  position: fixed;
  border-radius: 20px;
  bottom: 10%;
  right: 7%;
  border: 1px solid var(--gray);
}

.App[data-theme="dark"] .ThemeMenu {
  background-color: var(--off-black);
}

.App[data-theme="light"] .ThemeMenu {
  background-color: var(--light-gray);
}

.ThemeMenu img {
  margin: 1rem;
  height: 2rem;
  width: auto;
}

.toggle img {
  height: 1.5rem;
  width: auto;
  cursor: pointer;
}

iframe {
  display: none;
  width: 100%;
  height: 100%;
  grid-row: 2;
  grid-column: 1 / 3;
}

iframe.active {
  display: block;
  height: 20rem;
  border: 1px solid var(--gray);
  border-radius: 20px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: var(--off-black);
  box-shadow: 0 0 10px 5px var(--black);
}

iframe :not(.active) {
  display: none;
}
