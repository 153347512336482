:root {
  --off-white: #DDDDDD;
  --light-gray: #C0C0C0;
  --gray: #404040;
  --dark-gray: #171717;
  --off-black: #101010;
}

* {
  margin: 0;
  font-size: 18px;
  font-family: 'nunito sans', 'avenir', 'sans serif';
}

html {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    height: 100vh;
}

html::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}