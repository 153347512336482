.App[data-theme="dark"] header {
  background-color: var(--off-black);
}

.App[data-theme="light"] header {
  background-color: var(--light-gray);
}

header {
  color: inherit;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  max-width: 60%;
  min-width: 60%;
  border: 1px solid var(--gray);
  border-radius: 20px;
  box-shadow: 10px 10px 60px black;
  padding: 0.5rem;
  font-size: calc(10px + 2vmin);
  z-index: 50;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 20;
}

header a {
  color: inherit;
  padding: .25em .5em;
  cursor: pointer;
  text-decoration: none;
}

.App[data-theme="dark"] header a:not(.active) {
  background-color: var(--off-black);
}

.App[data-theme="light"] header a:not(.active) {
  background-color: var(--light-gray);
}

.App[data-theme="dark"] header a.active {
  background-color: var(--dark-gray);
  border: 1px solid var(--gray);
}

.App[data-theme="light"] header a.active {
  background-color: var(--light-gray);
  border: 1px solid var(--gray);
}

header a.active {
  border: 1px solid var(--gray);
  border-radius: 12.5px;
  box-shadow: inset 1.5px 1.5px 3px black;
}
